<template>
  <div class="row">
    <!-- begin::Create -->
    <div class="col-12">
      <!-- begin::Wizard -->
      <KTWizard ref="wizard" v-model="wizardIndex">
        <KTWizardNav>
          <KTWizardStep>
            <div class="wizard-title">
              User specifications
            </div>
          </KTWizardStep>
        </KTWizardNav>

        <KTWizardBody>
          <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
            <div class="col-xl-12 col-xxl-7">
              <!-- begin::Wizard Form-->
              <form class="form mt-0 mt-lg-10">
                <!-- begin::Step 1 -->
                <KTWizardStepContent>
                  <div class="mb-10 font-weight-bold text-dark">Provide user specifications</div>

                  <specifications-form
                    ref="specificationsForm"

                    :document="user"

                    action="CREATE"
                    :disabled="isLoadingForm"
                  />

                  <permission-group
                    ref="permissionGroup"
                    class="border-top pt-5"

                    :document="user"
                    :company="company"

                    action="CREATE"
                    :disabled="isLoadingForm"
                  />
                </KTWizardStepContent>
                <!-- end::Step 1 -->

                <!--begin: Wizard Actions -->
                <div v-if="isMounted" class="d-flex justify-content-between border-top pt-10">
                  <div class="mr-2">
                    <button
                      v-if="(wizardIndex > 0)"
                      key="wizardPreviousStep"
                      class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                      @click.prevent="$refs.wizard.previous()"
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    <button
                      v-if="(wizardIndex === this.$refs.wizard.steps.length - 1)"
                      key="wizardSubmit"
                      class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                      @click.prevent="submitForm"
                    >
                      Submit
                    </button>
                    <button
                      v-else
                      key="wizardNextStep"
                      class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      @click.prevent="$refs.wizard.next()"
                    >
                      Next Step
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
          </div>
        </KTWizardBody>
      </KTWizard>
      <!-- end::Wizard -->
    </div>
    <!-- end::Create -->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/layout/variables.scss";
@import "@vedicium/metronic-vue/src/assets/sass/pages/wizard/wizard-4.scss";
</style>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import { validations } from '@vedicium/core-vue';
import User from '@/libs/classes/user';
import companyLib from '@vedicium/core-vue/build/modules/company';

import specificationsForm from './components/specifications.form.vue';
import permissionGroup from './components/permission-group.form.vue';

export default {
  mixins: [Mixins.KTWizardV4],
  components: {
    specificationsForm,
    permissionGroup,
  },
  data () {
    return {
      isMounted: false,
      isLoadingForm: false,

      wizardIndex: 0,

      user: new User().merge({
        company_permissions: [{ company_guid: companyLib.current._meta.guid, permission_guid: null }],
      }),
      company: companyLib.current,
    };
  },
  mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Users', route: '/users/overview' }, { title: 'Add user' }]);

    this.$nextTick(() => {
      this.$set(this, 'isMounted', true);

      this.$refs.wizard.$on('step-change', this.onWizardStepChange.bind(this));
    });
  },
  beforeDestroy () {
    this.$refs.wizard.$off('step-change', this.onWizardStepChange.bind(this));
  },

  methods: {
    async onWizardStepChange (step, oldStep) {
      // Next step
      if (step > oldStep) {
        try {
          await this.validate(oldStep);
        } catch (e) {
          console.error(e);
          this.$refs.wizard.setStep(oldStep);
        }
      }
    },

    async validate (step = null) {
      const validationArray = [];

      // Add validators based by step
      if (step === null || step === 0) validationArray.push();

      await validations.validateArray(validationArray);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();
        await this.company.invite(this.user.email, this.user.getCompanyPermission(this.company._meta.guid).permission_id);
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this });
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      // TODO: Add disable_cache to route
      this.$router.push('/users');
    },
  },
};
</script>
